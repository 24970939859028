import ComponentRepository from './architecture/componentRepository';

document.addEventListener('DOMContentLoaded', function () {
    window.cr = new ComponentRepository({
        'header': () => import('./components/top-navigation'),
        'hero-swiper': () => import('./components/hero-swiper'),
        'short-content-swiper': () => import('./components/short-content-swiper'),
        'image-gallery': () => import('./components/image-gallery'),
        'accordion': () => import('./components/accordion'), 
        'top-navigation': () => import('./components/top-navigation'),
        'header-mobile': () => import('./components/header-mobile'),
        'media-with-video': () => import('./components/media-with-video'),
        'media-gallery': () => import('./components/media-gallery'),
        'swiper': () => import('../../node_modules/swiper/swiper-bundle.js'),
        'modal': () => import('./components/modal'),
        'map': () => import('./components/map'),
        'timeline': () => import('./components/timeline'),
        'news-overview': () => import('./components/news-overview'),
        'toggle-tabs': () => import('./components/toggle-tabs'),
        'link-list': () => import('./components/link-list'),
        'slider-swiper': () => import('./components/slider-swiper'),
        'single-dynamic-list': () => import('./components/single-dynamic-list'),
        'custom-animation': () => import('./components/custom-animation'),
        'multiple-dynamic-list': () => import('./components/multiple-dynamic-list'),
        /*
        This is an example component that demonstrates how to implement a filterable map. It should not be used in production sites as is but can serve as inspiration.
        'domain-filterable-map': () => import('./components/example-components/example-domain-filterable-map'),
        */
    });
});
